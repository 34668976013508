/* Button */
.btn {
	text-align: center; 
	padding: 10px 16px; 
	border-radius: 5px; 
	font-size:14px; 
	font-weight:500;
	letter-spacing: .5px; 
	text-transform: uppercase; 
	box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2); 
	border:none; 
	transition:0.2s; 
	color:white; 

	&:hover{
		transition:0.2s; 
		color:white; 
	}
}

.btn-add { 
	background-color:#4099ff; 

	&:hover{
		background-color:#3c8ce7; 
	}
}

.btn-delete { 
	background-color:#d21e4a; 

	&:hover{
		background-color:#a7193c; 
	}
}

.stockValid { 
	background-color:#26a69a; 
	height:15px; 
	width:15px; 
	border-radius: 25px; 
	display:inline-block; 
}

.stockError { 
	background-color:#a7193c; 
	height:15px; 
	width:15px; 
	border-radius: 25px; 
	display:inline-block; 
}