@import url('https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
@import url('https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/select2/4.0.5/css/select2.min.css');
@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');
@import url('https://cdn.datatables.net/1.10.20/css/dataTables.bootstrap.min.css');
// @import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');
// @import url('https://cdn.jsdelivr.net/npm/icheck-bootstrap@3.0.1/icheck-bootstrap.min.css');

@import 'assets/css/back/components/bootstrap/dist/css/bootstrap.min.css';
@import 'assets/css/back/components/font-awesome/css/font-awesome.min.css';
@import 'assets/css/back/components/Ionicons/css/ionicons.min.css';
@import 'assets/css/back/adminlte/AdminLTE.min.css';
@import 'assets/css/back/adminlte/skins/_all-skins.min.css';
@import 'assets/css/back/components/morris.js/morris.css';
@import 'assets/css/back/components/jvectormap/jquery-jvectormap.css';
@import 'assets/css/back/components/bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
@import 'assets/css/back/components/bootstrap-daterangepicker/daterangepicker.css';
@import 'assets/css/back/components/fullcalendar/dist/fullcalendar.min.css';
@import 'assets/css/back/components/fullcalendar/dist/fullcalendar.print.min.css';

@import 'assets/css/back/components/grid.scss';
@import 'assets/css/back/components/table.scss';
@import 'assets/css/back/components/form.scss';
@import 'assets/css/back/components/button.scss';

.testform::before {
  content: '';
  position: absolute;
  pointer-events: none;
}
.testform::after {
  content: '';
  position: absolute;
  pointer-events: none;
}

/* Général Class */
html,
body {
  font-family: Roboto !important;
  min-height: 100vh !important;
}

body#root {
  min-height: 100vh !important;
  // min-height: 100%;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.box-info {
  padding: 15px;
}

.main-content {
  transition: 0.2s;
}

.main-content .content {
  margin-left: 250px;
  height: 100vh;
  padding: 30px;
  padding-top: 95px;
}

.content .row.pad {
  padding-left: 15px;
  margin-left: 15px;
}

.block-white {
  background-color: white;
  padding: 5px 20px;
  box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-bottom: 30px;
}

h1.title-page {
  font-size: 18px;
  font-weight: 400;
  margin-top: 10px;
}

h1.title-page i {
  width: 45px;
  height: 45px;
  box-shadow: 0 2px 12px -3px rgba(0, 0, 0, 0.5);
  background-color: #7a3fbc;
  color: white;
  margin: 0 auto;
  text-align: center;
  border-radius: 5px;
  line-height: 40px;
  font-size: 24px;
  margin-right: 15px;
}

/*
.main-content .nav-top { height:70px; width:100%; background-color:white; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; }
.main-content .fil-ar { height:70px; width:100%; background-color:white; margin-top:15px; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; }
.main-content .fil-ar input { width:300px; float:left; margin-top:13px; margin-right:20px; }
.main-content .fil-ar .btn-add { background-color:#26a69a; text-align: center; padding: 10px 16px; color:white; transition:0.2s; border-radius: 5px; font-size:14px; letter-spacing: .5px; text-transform: uppercase; float:right; margin:15px 15px 0 0; box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14),0 3px 1px -2px rgba(0,0,0,0.12),0 1px 5px 0 rgba(0,0,0,0.2); }
.main-content .fil-ar .btn-add:hover { background-color:#1a9a8e;  transition:0.2s; }
.main-content .fil-ar .title-page { float:left; margin:21px 20px 0 20px; }
*/
.block-plugins {
  text-align: center;
}
.block-plugins h2 {
  margin-top: 15px;
  text-transform: uppercase;
}
.block-plugins img {
  margin-top: 15px;
}
.block-plugins p {
  margin-top: 15px;
  min-height: 80px;
  font-size: 14px;
}
/*
.options { width:100%; background-color:white; margin-top:15px; box-shadow: 0 1px 1px rgba(0,0,0,.04); border-radius: 5px; padding:15px; }
.options .block-option { display:flex; margin-top:15px; }
.options .block-option .switch { position: relative; display: inline-block; width: 41px; height: 21px; }
.options .block-option .switch input { opacity: 0; width: 0; height: 0; }
.options .block-option .slider { position: absolute; cursor: pointer; top: 0; left: 0; right: 0; bottom: 0; background-color: #ccc; -webkit-transition: .4s; transition: .4s; }
.options .block-option .slider:before { position: absolute; content: ""; height: 13px; width: 13px; left: 4px; bottom: 4px; background-color: white; -webkit-transition: .4s; transition: .4s; }
.options .block-option input:checked + .slider { background-color: #2196F3; }
.options .block-option input:focus + .slider { box-shadow: 0 0 1px #2196F3; }
.options .block-option input:checked + .slider:before { -webkit-transform: translateX(18px); -ms-transform: translateX(18px); transform: translateX(18px); }
.options .block-option .slider.round { border-radius: 34px; }
.options .block-option .slider.round:before { border-radius: 50%; } 
*/

.og-apercu {
  margin: 15px 0 30px 25px;
  line-height: 1.54;
  font-family: arial, sans-serif;
}

.og-apercu.block-white {
  padding: 20px 25px;
}

.og-title {
  color: rgb(26, 13, 171);
  font-size: 18px;
  line-height: 1.33;
}

.og-url {
  padding: 2px 0 1px 0;
  line-height: 1.43;
  font-size: 14px;
  color: #006621;
}

.og-desc {
  color: #545454;
  word-wrap: break-word;
}

.og-date {
  color: #777;
}

.og-apercu em {
  font-weight: bold;
  font-style: normal;
}

.box {
  box-shadow: none !important;
  border-top: 2px solid #d2d6de;
  border-radius: 2px;
}

.content-wrapper {
  background-color: white !important;
  min-height: 100vh !important;
  padding-top: 60px !important;
}

.small-box sup {
  font-size: 20px;
}

.box.box-info {
  border-top-color: #783fbd !important;
}

.btn {
  border-radius: 5px !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2) !important;
  border: 1px solid transparent !important;
  padding: 10px 16px !important;
  font-weight: 500 !important;
}

.btn-info,
.btn-add {
  background-color: #783fbd !important;
}

.btn-info:hover,
.btn-add:hover {
  background-color: #de3c83 !important;
  color: white !important;
}

.skin-blue .main-header {
  background: linear-gradient(to right, #ff0033, #d13c8c);

  .navbar,
  .logo {
    background: none !important;
  }

  .logo {
    border-right: 1px solid #37386d;
  }
}

.skin-blue .sidebar-menu > li a.active {
  border-left-color: #d13c8c !important;
}

.btn-delete {
  background-color: transparent;
  color: #d21e4a;
  text-align: center;
  display: block;
}

.btn-delete:hover {
  background-color: transparent;
  color: #d21e4a;
}

.breadcrumb a {
  color: #5f2182 !important;
}

.nav-tabs-custom > .nav-tabs > li.active {
  border-top-color: #d13c8c !important;
}

.skin-blue .sidebar-menu .treeview-menu > li > a.active i,
.skin-blue .sidebar-menu .treeview-menu > li > a:hover i {
  color: #d13c8c !important;
}

.content-wrapper .content .alert-info {
  background-color: #d13c8c !important;
  border-color: #d13c8c !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #d13c8c;
  border-color: #d13c8c;
}

.skin-blue .main-header .logo:hover,
.skin-blue .main-header .navbar .sidebar-toggle:hover {
  background-color: #933eae !important;
}

.sidebar-menu > li > a:hover {
  color: #d13c8c !important;
}

.content .article form {
  .col-md-10 .content .box-info {
    margin-top: 25px;
    .box-header {
      padding-top: 0;
      margin-bottom: 10px;
    }
  }

  .col-md-2 {
    margin: 15px 0 0 -15px;
    .box-info {
      .box-header {
        padding-top: 0;
        margin-bottom: 10px;
      }
      .box-footer .row {
        padding-top: 10px;
      }
    }
  }
}

#form_datePublish {
  line-height: normal;
}
#article-save .btn {
  margin: auto;
  display: block;
}

.wrapper {
  overflow-y: hidden !important;
  min-height: 100vh !important;
}

.productList-img {
  width: 100px;
  height: auto;
}

.content .productList.row {
  padding-left: 15px;
  margin-left: 15px;
  .btn {
    margin: 0 20px 15px 0;
  }
}

#produitForm,
#produitForm .basics .box-body {
  padding-top: 0;
}
#produitForm .box-info .box-header {
  padding-top: 0;
  margin-bottom: 10px;
}
.list-prodPhoto-item {
  width: 100px;
  flex: none;
  margin: 5px;
}

#right-col {
  margin-top: 10px;
  .box-info .box-header {
    padding-top: 0;
    margin-bottom: 10px;
  }
}

.list-prodPhoto {
  display: flex;
  flex-wrap: wrap;
}

.select2-container {
  width: 100% !important;
}

.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background-color: #d13c8c !important;
  border-color: #d13c8c !important;
}

.padl15 {
  padding-left: 15px;
}

input[type='number'].tva,
input[type='number'].coupon,
input[type='number'].ordre,
input[type='number'].cat-parent,
#right-col input[type='number'] {
  appearance: textfield !important;
  -webkit-appearance: textfield !important;
  -moz-appearance: textfield !important;
}

.question .col-md-6 {
  margin: 15px 15px 0 -15px;
}
.resultat .col-md-4 {
  margin: 15px 0 0 -15px;
}
.question #form_save {
  margin: -15px 0 0 15px;
}
.resultat #form_save {
  margin: -30px 0 0 30px;
}

.block-plugins .btn {
  display: block !important;
}
.block-plugins .btn:hover {
  background-color: #d13c8c !important;
  color: white !important;
}

.bagheram-box-shadow {
  -webkit-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.22);
  -moz-box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.22);
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.22);
}

.bagheram-rounded {
  border-radius: 15px !important;
  padding: 20px;
  padding-left: 30px;
  padding-right: 30px;
}

.bagheram-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bagheram-input {
  border-radius: 10px !important;
  height: 45px;
}

.pagination > .active > a {
  background-color: #783fbd !important;
  border-color: #783fbd !important;
}

.fa-chevron-left:before {
  color: #783fbd !important;
}

.bagheram-navigation-menu {
  border: thin solid red;
  min-height: 100vh !important;
}

.custom-combobox {
  position: relative;
  display: inline-block;
}
.custom-combobox-toggle {
  position: absolute;
  top: 0;
  bottom: 0;
  margin-left: -1px;
  padding: 0;
}
.custom-combobox-input {
  margin: 0;
  padding-top: 2px;
  padding-bottom: 5px;
  padding-right: 5px;
}

.select2-selection__rendered {
  // background-color: red !important;
  color: #a1a1a1 !important;
}

.select2-selection__rendered[aria-readonly] {
  // background-color: red !important;
  color: black !important;
}

.select2-results__option[aria-disabled] {
  display: none !important;
}

.bagheram-row-center {
  // border: thin solid green !important;
  display: flex !important;
  flex-direction: row !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  flex-wrap: wrap;
}

.btn-file {
  position: relative;
  overflow: hidden;
  // height: 30px !important;
  margin-left: 20px;
  background-color: #d5d5d5 !important;
  color: black !important;
}
.btn-file input[type='file'] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 50%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  cursor: inherit;
  display: block;
}

.bagheram-btn-file {
  height: 35px !important;
  font-size: 8pt !important;
}

.bagheram-modal {
  border: 2px solid red !important;
}

.modal-content {
  border-radius: 10px !important;
}

.modal-header {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.bagheram-box-dashboard {
  -webkit-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4) !important;
  -moz-box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.4) !important;
  border-radius: 5px !important;
}

.btn-group .searchinput {
  max-width: 200px !important;
  height: 40px !important;
  border-radius: 5px !important;
}

.btn-group .searchclear {
  position: absolute !important;
  right: 10px !important;
  top: 0 !important;
  bottom: 0 !important;
  height: 14px !important;
  margin: auto !important;
  font-size: 14px !important;
  cursor: pointer !important;
  color: #ccc !important;
}

.bagheram-th-hover:hover {
  background-color: #d5d5d5;
}

.bagheram-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 40px;
}

.date input {
  // background-color: red !important;
  height: 40px;
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.date .input-group-addon {
  // background-color: red !important;
  height: 40px;
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.bagheram-input-group-addon {
  border: none !important;
}

.select2-container--default .select2-selection--single {
  height: 40px !important;
  border-radius: 5px !important;
  padding: 9px 15px !important;
}

.select2-container--default .select2-selection__arrow {
  // height: 40px !important;
  // border-radius: 5px !important;
  margin-top: 0px !important;
  // border: thin solid red !important;
  height: 34px !important;
  // padding: 9px 15px !important;
}

.date-picker-input {
  max-height: 40px !important;
}

.react-google-flight-datepicker {
  // border: thin solid red !important;
  padding: 0px !important;
}

.form-control-refDataPicker {
  // border: thin solid green !important;
  padding: 0px !important;
}

.form-control-refDataPicker .open {
  // border: thin solid green !important;
  max-width: 400px;
}

/* The Modal (background) */
.modal2 {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 20000; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal2-content {
  background-color: #fefefe;
  margin: calc(100vh / 9) auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  border-radius: 10px !important;
  max-width: 1000px;
  width: 90%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.modal2 .rdrDateRangePickerWrapper {
  display: inline-flex !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  flex-wrap: wrap-reverse !important;
  overflow-x: scroll !important;
}

.bg-darkorange {
  background-color: brown !important;
  color: white !important;
}

.bg-blueviolet {
  background-color: blueviolet !important;
  color: white !important;
}

.bg-brown {
  background-color: brown !important;
  color: white !important;
}

.bg-lightgreen {
  background-color: lightgreen !important;
  color: white !important;
}

/* Style the header */
.header2 {
  padding: 10px 0px;
  // background: #555;
  width: 100%;
  // color: #f1f1f1;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky2 {
  position: fixed;
  z-index: 20;
  top: 0;
  padding-top: 60px;
  // width: 100%
}

.sticky3 {
  position: fixed;
  z-index: 19;
  top: 0;
  padding-top: 130px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  // width: 100%
}

.sticky4 {
  position: fixed;
  z-index: 19;
  top: 0;
  padding-top: 170px;
  padding-left: 20px;
  padding-right: 20px;
  background: white;
  // width: 100%
}

.sticky2 + .content2 {
  padding-top: 140px;
}

.sticky3 + .content3 {
  padding-top: 80px;
  border: none !important;
}

.sticky3 + .content4 {
  padding-top: 100px;
}

.spinner {
  width: 65px;
  height: 65px;
  position: relative;
  margin: 10px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}

@media (max-width: 768px) {
  .content-wrapper {
    padding-top: 100px !important;
  }
}

@media (min-width: 400px) {
  .form-control-refDataPicker .open {
    margin-left: -60px;
  }
}

@media (min-width: 600px) {
  .form-control-refDataPicker .open {
    margin-left: -10px;
  }
}

@media (min-width: 768px) {
  .form-control-refDataPicker .open {
    margin-left: -30px;
  }
}

.message {
  padding: 0 !important;
  margin: 0 !important;
  background-size: cover;
  overflow-y: auto;
  height: calc(100% - 120px);
  max-height: 350px;
  overflow: scroll;
}
.message-body {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
}
.message-main-receiver {
  padding: 6px 20px;
  max-width: 60%;
}
.message-main-sender {
  padding: 3px 20px !important;
  margin-left: 40% !important;
  max-width: 60%;
}

.receiver {
  width: auto !important;
  padding: 4px 10px 7px !important;
  border-radius: 10px 10px 10px 0;
  background: #bebdb8;
  font-size: 12px;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  word-wrap: break-word;
  display: inline-block;
}

.sender {
  float: right;
  width: auto !important;
  background: #5097a4;
  border-radius: 10px 10px 0 10px;
  padding: 4px 10px 7px !important;
  font-size: 12px;
  //text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  display: inline-block;
  word-wrap: break-word;
}
.message-text {
  margin: 0 !important;
  padding: 5px !important;
  word-wrap: break-word;
  font-weight: 200;
  font-size: 14px;
  padding-bottom: 0 !important;
  color: black;
}

.message-time {
  margin: 0 !important;
  margin-left: 50px !important;
  font-size: 9px;
  text-align: right;
  color: white;
}
.notif {
  width: 20px;
  height: 20px;
  background-color: #fa3e3e;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: x-small;
  border-radius: 50%;
  position: absolute;
  right: -3px;
  top: -7px;
}
